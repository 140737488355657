import Modal from "react-awesome-modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./popUp.scss";
import React from "react";
import { PostAppointmentData } from "../api/postApi";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PopUpPage(props) {
  const successNotify = () => {
    toast.success("Form Submitted Sucessfully");
  };

  const errorNotify = () => {
    toast.error("Something Wrong!!");
  };
  return (
    <>
      {/* <ToastContainer />
      <div className="pop-up-block">
        <Modal show={props.show} onHide={props.hide} centered>
          <Modal.Header closeButton>
            <Modal.Title className="pop-heading">{props.modalHead}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                doctor_name: "",
                date: "",
                time: "",
                contact: "",
                description: "",
              }}
              validationSchema={Yup.object({
                doctor_name: Yup.string()
                  .min(3, "Too Short!")
                  .max(20, "Too Long!")
                  .required("Required!"),
                contact: Yup.string()
                  .required("Required!")
                  .max(10, "Too Long!")
                  .matches(
                    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                    "Invalid phone number!"
                  ),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const postData = new FormData();
                postData.append("doctor_name", values.doctor_name);
                postData.append("time", values.time);
                postData.append("date", values.date);
                postData.append("description", values.description);

                debugger;
                PostAppointmentData(postData).then((res) => {
                  debugger;
                  if (res.status === 200) {
                    props.hide();
                    successNotify();
                    resetForm();
                  } else {
                    errorNotify();
                  }
                });
                setSubmitting(false);
              }}
            >
              {() => {
                return (
                  <Form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-form">
                          <p className="content">Full Name:</p>
                          <Field
                            name="doctor_name"
                            type="text"
                            className="text-field"
                            placeholder="Your full name"
                          />
                          <span className="error">
                            <ErrorMessage name="doctor_name" />
                          </span>
                        </div>
                        <div className="input-form">
                          <p className="content">Date:</p>
                          <Field
                            name="date"
                            type="date"
                            className="text-field"
                            placeholder="00-00-2021"
                          />
                          <span className="error">
                            <ErrorMessage name="date" />
                          </span>
                        </div>
                        <div className="input-form">
                          <p className="content">Preffered Time:</p>
                          <Field
                            name="time"
                            type="number"
                            className="text-field"
                            placeholder="Appointment time(2 am)"
                          />
                          <span className="error">
                            <ErrorMessage name="time" />
                          </span>
                        </div>
                        <div className="input-form">
                          <p className="content">Contact Number:</p>
                          <Field
                            name="contact"
                            type="number"
                            className="text-field"
                            placeholder="Your contact number"
                          />
                          <span className="error">
                            <ErrorMessage name="contact" />
                          </span>
                        </div>
                        <div className="input-form">
                          <p className="content">Description:</p>
                          <Field
                            as="textarea"
                            name="description"
                            type="text"
                            className="text-field"
                            placeholder="Your Description here"
                          />
                          <span className="error">
                            <ErrorMessage name="description" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <button type="submit" className="main-btn btn-radius">
                      {props.close}
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Body>
        </Modal>
      </div> */}

      <Formik
        initialValues={{
          patient_name: "",
          date: "",
          time: "",
          contact: "",
          description: "",
        }}
        validationSchema={Yup.object({
          patient_name: Yup.string()
            .min(3, "Too Short!")
            .max(20, "Too Long!")
            .required("Required!"),
          contact: Yup.string()
            .required("Required!")
            .max(11, "Too Long!")
            .matches(
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
              "Invalid phone number!"
            ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const postData = new FormData();
          postData.append("patient_name", values.patient_name);
          postData.append("time", values.time);
          postData.append("contact", values.contact);
          postData.append("date", values.date);
          postData.append("desc", values.description);

          PostAppointmentData(postData).then((res) => {
            if (res.status === 200) {
              props.closeModal();
              successNotify();
              resetForm();
            } else {
              errorNotify();
            }
          });
          setSubmitting(false);
        }}
      >
        {() => {
          return (
            <div className="modalss">
              <div className="pop-up-block">
                <ToastContainer />
                <Modal
                  className="pop-up"
                  closeModal={props.closeModal}
                  visible={props.visible}
                  centered
                  width="500"
                  height="600"
                  effect="fadeInUp"
                  onClickAway={() => props.closeModal()}
                >
                  <div className="full-content">
                    <h4 className="pop-heading">
                      {props.modalHead}
                      <span
                        onClick={() => props.closeModal()}
                        className="close cross "
                        aria-hidden="true"
                      >
                        x
                      </span>
                    </h4>
                    <Form>
                      <div className="content-body">
                        <div className="input-form">
                          <p className="content ">Full Name:</p>
                          <Field
                            name="patient_name"
                            type="text"
                            className="text-field "
                            placeholder="Your full name"
                          />
                          <span className="error">
                            <ErrorMessage name="patient_name" />
                          </span>
                        </div>
                        <div className="input-form">
                          <p className="content">Date:</p>
                          <Field
                            name="date"
                            type="date"
                            className="text-field"
                            placeholder="00-00-2021"
                          />
                          <span className="error">
                            <ErrorMessage name="date" />
                          </span>
                        </div>
                        <div className="input-form">
                          <p className="content">Prefered Time:</p>
                          <Field
                            name="time"
                            type="time"
                            className="text-field"
                          />
                          <span className="error">
                            <ErrorMessage name="time" />
                          </span>
                        </div>
                        <div className="input-form">
                          <p className="content ">Contact Number:</p>
                          <Field
                            name="contact"
                            type="text"
                            max="10"
                            pattern="^[0-9]*$"
                            className="text-field"
                            placeholder="Your contact number"
                          />
                          <span className="error">
                            <ErrorMessage name="contact" />
                          </span>
                        </div>
                        <div className="input-form">
                          <p className="content">Description:</p>
                          <Field
                            as="textarea"
                            name="description"
                            type="text"
                            className="text-field"
                            placeholder="Your Description here"
                          />
                          <span className="error">
                            <ErrorMessage name="description" />
                          </span>
                        </div>
                        <div className="input-form">
                          <button
                            type="submit"
                            className="main-btns btn-radius "
                          >
                            {props.close}
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Modal>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
}

export default PopUpPage;
