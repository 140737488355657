/* eslint-disable react/no-unescaped-entities */
//@ts-check
import React from "react";
import { SliderPage } from "../SliderPage/SliderPage";
import "./video.scss";

const Video = () => {
  return (
    <>
      <div className="video-section section-top">
        <div className="container">
          <div className="text-center title-center">
            <h2 className="main-title">
              All encompassing care, a fresh approach to dentistry
            </h2>
            <div className="title-line"></div>
          </div>
          <div className="frame-box">
            <iframe
              src="https://www.youtube.com/embed/AqJIsgnCNEc"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
            ></iframe>

            {/* <iframe
              src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FSupremeDentalNepal%2Fvideos%2F1071623863646048%2F&show_text=false&width=560&t=0"
              width="560"
              height="314"
              style={{ border: "none", overflow: "hidden" }}
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              allowFullScreen={true}
            ></iframe> */}
          </div>
          <div>
            <SliderPage />
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
