import React, { useState } from "react";
import "./features.scss";

function Features() {
  return (
    <>
      <div className="feature-section section-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="sticky-scroll">
                <h1 className="main-title">
                  Hello! We Are Supreme
                  <span className="card-block">Dental Care.</span>
                </h1>
                <div className="title-line"></div>
                <div className="pt-50">
                  <p className="content">
                    Supreme Dental Care, with the help of expert dentists and a
                    strong team, founded on July 8 2021 at Banepa, Kavre. We
                    provide affordable, prompt and quality dental treatment to
                    its patients. With the Motto " Be True to your Teeth " we
                    have established the Dental Care with Modern Technological
                    Equipments and hygienic environment.
                  </p>
                  {/* <h6 className="text-color">
                    Our Guiding Mission & Principles are to:
                  </h6>
                  <div className="content">
                    <ul>
                      <li>
                        Promote good oral health by providing the highest level
                        of care utilizing the latest technology.
                      </li>
                      <li>
                        Prevent disease through education and preventative
                        treatment options.
                      </li>
                      <li>Relieve anxiety with patience and communication.</li>
                      <li>Treat all patients with honesty and compassion.</li>
                      <li>
                        Provide our Dental Service in Remote areas of Nepal
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="border-top">
                    <div className="mtb-40">
                      <p className="circle">
                        <i class="bi bi-clock"></i>
                      </p>

                      <h6 className="text-color blue">On Time, Anytime</h6>
                      <p className="content">
                        At Supreme Dental Care, each of our offices is
                        passionate about providing you with general,
                        restorative, and emergency dental services that exceed
                        your expectations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="border-top">
                    <div className="mtb-40">
                      <p className="circle green">
                        <i class="bi bi-file-plus-fill"></i>
                      </p>
                      <h6 className="text-color text-color-green">
                        Provide Better Dental Services
                      </h6>
                      <p className="content">
                        Crowns, bridges, full and partial dentures, x-rays, and
                        dental cleanings.Preventive care, periodontal therapy,
                        and nutritional counseling.Implants - placement and
                        restoration,Fillings, root canals, and extractions
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="border-top">
                    <div className="mtb-40">
                      <p className="circle orange">
                        <i class="bi bi-emoji-smile"></i>
                      </p>
                      <h6 className="text-color text-color-orange">
                        Modern care with a smile
                      </h6>
                      <p className="content">
                        Promote good oral health by providing the highest level
                        of care utilizing the latest technology in helping you
                        brighten and whiten your smile without compromising your
                        overall dental health.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
