import React, { useState, useEffect } from "react";
import "./dentalPage.scss";
import { domain } from "../api/AxiosInstance.js";
import { GetServiceDetail } from "../api/getApi";
import PopUpPage from "../popUp/popUp.js";

function DentalPage() {
  const [visible, setVisible] = useState(false);

  const [serviceDetail, setServiceDetail] = useState([]);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    GetServiceDetail().then((response) => {
      setServiceDetail(response.data);
    });
  }, []);
  return (
    <>
      <div className="dental-page mb-30">
        <div className="img-overlay"></div>
        <div className="breadcrumb-content">
          <div className="bg-img"></div>
          <div className="dental-text">
            <div className="text-content">
              <h3 className="mb-0 small-txt">Welcome to</h3>
              <h1 className="mb-0 large-txt">Supreme Dental Care</h1>
              <h3 className="small-txt">in Banepa</h3>
              <div className="title-line"></div>
            </div>
            <h3 className="txt">Because your smile is a passion.</h3>
            <div className="mtb-20">
              <button
                className="main-btn btn-radius"
                type="button"
                onClick={openModal}
              >
                Schedule you visit
              </button>
              <a
                href="tel:011665492"
                className="transparent-btn btn-radius"
                type="button"
              >
                Call (011665492)
              </a>
            </div>
          </div>
        </div>
        <div className="container section-top"></div>
      </div>
      <PopUpPage
        visible={visible}
        closeModal={closeModal}
        modalHead={"Requesting Appointment"}
        close={"Submit"}
      />
    </>
  );
}

export default DentalPage;
