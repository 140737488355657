import { GetTeamData } from "../api/getApi";
import React, { useEffect, useState } from "react";
import PopUpPage from "../popUp/popUp";
import { domain } from "../api/AxiosInstance";
import "./SliderPage.scss";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export const SliderPage = () => {
  const [visible, setVisible] = useState(false);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const [teamData, setTeamData] = useState(null);

  useEffect(() => {
    GetTeamData().then((response) => {
      setTeamData(response.data);
    });
  }, []);

  return (
    <div className="center-align" id="our-team">
      {teamData?.length && (
        <OwlCarousel
          className="owl-theme"
          loop
          margin={10}
          autoplay={true}
          autoplayTimeout={2000}
          autoplayHoverPause={true}
          items={1}
          nav
        >
          {teamData?.map((item, index) => {
            return (
              <div className="row middle mt-60 item">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="shadow-wrapper" key={index}>
                    <img
                      className="video-img"
                      src={domain + item.image.url}
                      alt="service"
                    />
                    <div className="box-shadow">
                      <h6 className="main-title text-uppercase">
                        {" "}
                        {item.name}
                      </h6>
                      <p className="content text-uppercase">{item.position}</p>
                      <button
                        className="main-btn btn-radius"
                        type="button"
                        onClick={openModal}
                      >
                        Book an appointment
                      </button>
                      {visible === true && (
                        <PopUpPage
                          visible={visible}
                          closeModal={closeModal}
                          modalHead={"Appointment Heading"}
                          close={"Submit"}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12">
                  <div className="align-block">
                    <h1 className="main-title">Meet Our Expert</h1>
                    <div className="title-line"></div>
                    <div className="pt-50">
                      <p className="content">{item.for_word}</p>
                    </div>
                    {/* <button className="main-btn btn-radius" type="button">
                    Learn More
                  </button> */}
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      )}
    </div>
  );
};
