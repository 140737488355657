import React, { useState } from "react";
import "./footer.scss";

function Footer() {
  return (
    <div className="footer-section section-top">
      <div className="container">
        <div className="text-center">
          <div className="logo-img footer-logo ">
            <img
              src={require("../../assets/image/logo.png").default}
              alt="logo"
            />
          </div>
          <div>
            <ul class="footer-list" type="none">
              <li class="list-names">
                <a class="nav-link" href="#">
                  About
                </a>
              </li>
              <li class="list-names">
                <a class="nav-link" href="#">
                  Our Team
                </a>
              </li>
              <li class="list-names">
                <a class="nav-link" href="#">
                  Services
                </a>
              </li>
              {/* <li class="list-names">
                <a class="nav-link" href="#">
                  Help
                </a>
              </li>
              <li class="list-names">
                <a class="nav-link" href="#">
                  Privacy Policy
                </a>
              </li> */}
            </ul>
          </div>
          <div className="flex-block mt-60">
            <p className="content copy">
              &copy; 2021 Supreme Dental Care.All rights reserved | Develop by{" "}
              <a href="https://axiossoftwork.com" target="_blanks">
                Axios Softwork
              </a>
            </p>
            <div>
              <ul type="none" className="flex-block">
                <li className="icon-bg mr-20">
                  {/* <i class="bi bi-instagram"></i> */}

                  <a
                    href="https://www.facebook.com/SupremeDentalNepal"
                    target="_blanks"
                  >
                    {" "}
                    <i class="bi bi-facebook sdfgh"></i>
                  </a>
                </li>
                {/* <li className="icon-bg">
                  <i class="bi bi-twitter"></i>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
