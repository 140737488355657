import React, { useState, useEffect } from "react";
import Rating from "../rating/rating.js";
import "./ourService.scss";
import { GetServiceDetail } from "../api/getApi";
import PopUpPage from "../popUp/popUp.js";
import { domain } from "../api/AxiosInstance.js";

const OurService = () => {
  const [visible, setVisible] = useState(false);

  const [serviceDetail, setServiceDetail] = useState([]);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    GetServiceDetail().then((response) => {
      setServiceDetail(response.data);
    });
  }, []);

  return (
    <>
      <div className="service-section section-top" id="service">
        <div className="container">
          <div className="text-center">
            <h2 className="main-title title-center">Our Services</h2>
            <div className="title-line"></div>
          </div>
          <div className="row">
            {serviceDetail.services?.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 hide" key={index}>
                  <div className="price-block">RS. {item.price}</div>
                  <div className="service-img">
                    <div className="img-overlay"></div>
                    <img src={domain + item.image.url} alt="image" />
                    <h6 className="text-overlay">{item.name}</h6>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row mt-60" id="testimonial">
            <div className="col-lg-6 col-md-5 col-sm-12">
              <div className="sticky-scroll">
                <h1 className="main-title">
                  Why <span className="card-block">Choose Us?</span>
                </h1>
                <div className="title-line"></div>
                <div className="pt-50">
                  <div className="content">
                    <ul>
                      <li>
                        Gentle & Caring Team of Expert Dentists and Specialist.
                      </li>
                      <li>
                        Comprehensive Dental Care with Modern Technological
                        Equipment
                      </li>
                      <li>
                        OPG/ RVG/ Endomotor with APEX Locator / Portable X-ray
                      </li>
                      <li>
                        Safe and Hygienic Service/ Sterilization with B Class
                        Autoclave/ UV Cabinet Chamber
                      </li>
                      <li>RCT within a day with Endomotor facility</li>
                      <li>
                        Professional Teeth Whitening, Polishing with Modern
                        Technology - Aqua Care Facility
                      </li>
                      <li>Evening and Weekend Appointments</li>

                      <li>
                        Provide our own Brand Toothpaste, Oral Gargle according
                        to Age Group ( Child and Adult)
                      </li>
                      <li>
                        Social Responsibility, Dental Camps, Friendly Staffs,
                        Inexpensive Service.
                      </li>
                      <li>
                        Prime Location with eye catchy view ( in heart of Banepa
                        City)
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  className="main-btn btn-radius request-btn"
                  type="button"
                  onClick={openModal}
                >
                  Request Appointment
                </button>
              </div>
            </div>

            <div className="col-lg-6 col-md-7 col-sm-12 card-block">
              <div className="row">
                {serviceDetail.testamonials?.slice(0, 4).map((item, index) => {
                  return (
                    <div
                      className="col-lg-6 col-md-6 col-sm-12  alignment1"
                      key={index}
                    >
                      <div className="card-block full-length-box">
                        <div className="mr-20">
                          <div className="box-shadow">
                            <h6 className="main-title text-uppercase">
                              {" "}
                              {item.name}
                            </h6>
                            <div className="ptb-10">
                              <Rating rate={item.rating} />
                            </div>
                            <p className="content">{item.statement}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <PopUpPage
        visible={visible}
        closeModal={closeModal}
        modalHead={"Requesting Appointment"}
        close={"Submit"}
      />
    </>
  );
};

export default OurService;
