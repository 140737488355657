import React from "react";
import "./contacts.scss";

function Contacts() {
  return (
    <div className="contact-section section-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <h2 className="main-title">Our Office</h2>
            <div className="title-line"></div>
            <div className="pt-50">
              <div className="flex-block">
                <i class="bi bi-geo-alt"></i>
                <div className="contact-text">
                  <p className="content">Woku Tole, Ganesthan, Banepa</p>
                </div>
              </div>
              <div className="flex-block">
                <i class="bi bi-telephone"></i>
                <div className="contact-text">
                  <p className="content">
                    <a href="tel:011665492">+011-665492</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12">
            <h2 className="main-title">Hours</h2>
            <div className="title-line"></div>
            <div className="pt-50">
              <div className="flex-block time">
                <p className="content">
                  <i class="bi bi-clock"></i>
                  <span>Everyday</span>
                </p>
                <p className="content">7AM-7PM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
