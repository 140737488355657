import React, { useState, useEffect } from "react";
import "../../styles/main.scss";
import "./header.scss";
import PopUpPage from "../popUp/popUp";

const Header = () => {
  const [visible, setVisible] = useState(false);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  let navbarClasses = ["navbar"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  return (
    <header className={navbarClasses.join(" ")}>
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img
              src={require("../../assets/image/logo.png").default}
              alt="logo"
              className="logo-img"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#service">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#testimonial">
                  Testamonial
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#our-team">
                  Our Team
                </a>
              </li>
              <li className="nav-item">
                <button
                  className="main-btn btn-radius"
                  type="button"
                  onClick={openModal}
                >
                  Request Appointment
                </button>
                {visible === true && (
                  <PopUpPage
                    visible={visible}
                    closeModal={closeModal}
                    modalHead={"Requesting Appointment"}
                    close={"Submit"}
                  />
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Header;
