import React, { useState } from "react";
// import "./location.scss";

function MapLocations() {
  return (
    <>
      <div className="location section-top">
        <div className="container">
          <div className="map-area">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3534.781723699234!2d85.5222666500027!3d27.631276135354828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb08cde8ccd2e7%3A0x49ac2bfcbe2c8e5!2sRed%20Cross!5e0!3m2!1sen!2snp!4v1633337805736!5m2!1sen!2snp"
              width="100%"
              height="400"
              frameBorder="0"
              allowFullScreen=""
              loading="lazy"
              style={{ borderRadius: "10px" }}
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default MapLocations;
